'use strict';

var siteInit = {

	DOMready: function() {

		if ( $('.js-slider--primary').length > 0 ) {
			siteInit.sliderPrimary();
		}

		if ( $('.js-carousel').length > 0 ) {
			siteInit.carousel();
		}

		if ( $('.js-carousel-big').length > 0 ) {
			siteInit.carouselBig();
		}

		if ( $('.js-slider--secondary').length > 0 ) {
			siteInit.sliderSecondary();
		}

		if ( $('.js-brochure').length > 0 ) {
			siteInit.toggleList();
		}

		if ( $('[href$=".jpg"], [href$=".png"], [href$=".gif"]').length > 0 ) {
			siteInit.modal();
		}

		if ( $('.js-navicon').length > 0 ) {
			siteInit.mobileNavigation();
		}

		$.scrollIt();

	},

	DOMload: function() {

		if ( $('.js-loader').length > 0 ) {
			siteInit.loader();
		}

	},

	// Loader
	loader: function() {

		$("body").removeClass('is-loading').addClass('is-loaded');
		$(".js-loader").fadeOut('slow');


	},

	// Slider
	sliderPrimary: function() {

		$(".js-slider--primary").owlCarousel({
			items: 1,
			autoplay: true,
			loop:false,
			nav:false,
			dots:true
		});

	},

	// Toggle List
	toggleList: function() {
		$(".js-brochure").on('click', function(e) {
			e.preventDefault();
			$(this).next().slideToggle();
		});
	},

	// Slider
	sliderSecondary: function() {

		var sync1 = $(".js-slider--sync1");
		var sync2 = $(".js-slider--sync2");

		sync1.owlCarousel({
			items: 1,
			loop:false,
			nav:true,
			dots:false
		}).on('initialized.owl.carousel', function(event) {

			console.log(event);

		}).on('changed.owl.carousel', function(event) {

			var currentItem = event.item.index;
			var items = event.item.count;
			sync2.trigger('to.owl.carousel', [currentItem, 500]);
			$('.gallery__number').html((currentItem+1) + '/' + items );

		});

		sync2.owlCarousel({
			items: 1,
			loop:false,
			nav:false,
			dots:false
		}).on('changed.owl.carousel', function(event) {
			var currentItem = event.item.index;
			sync1.trigger('to.owl.carousel', [currentItem, 500]);
		});

	},

	// Carousel
	carousel: function() {

		$(".js-carousel").owlCarousel({
			margin: 19,
			loop:false,
			nav:true,
			dots:true,
			responsive : {
				0 : {
					items: 1,
					nav:false,
				},
				992 : {
					nav:true,
					items: 2,
				}
			}
		});

	},

	// Carousel Big
	carouselBig: function() {

		$(".js-carousel-big").owlCarousel({
			margin: 19,
			loop:false,
			dots:false,
			responsive : {
				0 : {
					items: 2,
					nav:false,
					dots:true,
				},
				768 : {
					items: 3,
					nav:false,
					dots:false,
				},
				992 : {
					nav:true,
					items: 6,
				}
			}
		});

	},

	// Modal
	modal: function() {

		$('[href$=".jpg"], [href$=".png"], [href$=".gif"]').colorbox({
			transition: 'elastic',
			speed: 400,
			opacity: 0.8,
			slideshow: true,
			slideshowSpeed: 4000,
			itemsnitialWidth: 50,
			initialHeight: 50,
			maxWidth: '90%',
			maxHeight: '90%',
		});

	},

	// Mobile navigation
	mobileNavigation: function() {

		$('body').on( 'click', '.js-navicon', function( event ) {

			var $el = $(this).add('#navigation');
			$( $el ).toggleClass('is-active');
			$('body').toggleClass('is-active');
			event.preventDefault();

		});

	}

};

$( document ).ready( function() {
	siteInit.DOMready();
});

$( window ).load( function() {
	siteInit.DOMload();
});
